import React, { useState, useEffect } from 'react';
import * as components from './components';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { AUTH_GET_USER } from './util/Constants';
import axios from 'axios';
import './input.css';

/**
 * The main component of the application.
 * Renders different views based on the user's authentication status.
 */
const App: React.FC = () => {
	const [user, setUser] = useState<string | null>(
		sessionStorage.getItem('user')
	);
	const [loadingUser, setLoadingUser] = useState(
		sessionStorage.getItem('user') === null
	);

	useEffect(() => {
		if (user !== null) {
			return;
		}
		const getUser = async () => {
			try {
				const url = AUTH_GET_USER;
				const promise = axios.get(url, { withCredentials: true });
				if (promise == undefined) {
					// should only happen during testing (i.e. axios is mocked)
					setLoadingUser(false);
					setUser('');
					return;
				}
				const { data } = await promise;
				if (data && data.email) {
					setUser(data.email);
					sessionStorage.setItem('user', JSON.stringify(data.email));
				}
			} catch (err) {
				// login failed or unauthorized
				setUser(null);
			}
			setLoadingUser(false);
		};
		getUser();
	});

	if (loadingUser) {
		return <div>Loading...</div>;
	}

	if (user === null) {
		return (
			<BrowserRouter>
				<components.Unauthorized />
			</BrowserRouter>
		);
	}

	// At this point we can assume that user now stores the email of the
	// authenticated user.
	// We can add more logic to only render the following if the authenticated
	// user is one we want seeing this information.

	return (
		<BrowserRouter>
			<components.Sidebar />
			<Routes>
				<Route path="/dashboard" element={<components.Dashboard />} />
				<Route path="/tickets" element={<components.Tickets />} />
				<Route path="/contacts" element={<components.Contacts />} />
				<Route path="/contacts/create" element={<components.CreateClient />} />
				<Route
					path="/contacts/import-clients"
					element={<components.ImportClients />}
				/>
				<Route path="/tickets/create" element={<components.CreateTicket />} />
				<Route path="/*" element={<components.Dashboard />} />
			</Routes>
		</BrowserRouter>
	);
};

export default App;
