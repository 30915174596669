import React from 'react';
import { Ticket, Priority, Status } from '../../util/Types';
import { Contact } from '../../util/Types';

type TicketFormProps = {
	contacts: Contact[];
	ticket: Ticket;
	onChange: (ticket: Ticket) => void;
};

const TicketForm: React.FC<TicketFormProps> = ({
	contacts,
	ticket,
	onChange,
}) => {
	const handlePriorityChange = (
		event: React.ChangeEvent<HTMLSelectElement>
	) => {
		onChange({ ...ticket, priority: event.target.value as Priority });
	};

	const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		onChange({ ...ticket, status: event.target.value as Status });
	};

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		onChange({ ...ticket, [name]: value });
	};

	const handleContactChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		onChange({ ...ticket, contact_id: parseInt(event.target.value) });
	};

	return (
		<div>
			<div>
				<label>
					Priority<span style={{ color: 'red' }}> *</span>
				</label>
				<select
					id="prioritySelect"
					className="input-field"
					onChange={handlePriorityChange}
					value={ticket.priority}
				>
					{Object.values(Priority).map((priority) => (
						<option key={priority} value={priority}>
							{priority}
						</option>
					))}
				</select>
				<label>
					Status<span style={{ color: 'red' }}> *</span>
				</label>
				<select
					id="prioritySelect"
					className="input-field"
					onChange={handleStatusChange}
					value={ticket.status}
				>
					{Object.values(Status).map((status) => (
						<option key={status} value={status}>
							{status}
						</option>
					))}
				</select>
				<label>
					Description<span style={{ color: 'red' }}> *</span>
				</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({ ...ticket, description: event.target.value });
					}}
					required
				/>
				<label>
					Contact<span style={{ color: 'red' }}> *</span>
				</label>
				<select
					id="prioritySelect"
					className="input-field"
					onChange={handleContactChange}
					value={ticket.contact_id}
				>
					{contacts.map((contact) => (
						<option key={contact.id} value={contact.id}>
							{contact.name}
						</option>
					))}
				</select>
				<label>
					Author<span style={{ color: 'red' }}> *</span>
				</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({ ...ticket, author: event.target.value });
					}}
					required
				/>

				<label>
					Creation Date<span style={{ color: 'red' }}> *</span>
				</label>
				<input
					type="date"
					id="creationDateInput"
					name="creationDate"
					className="input-field"
					onChange={handleInputChange}
					value={
						ticket.creation_date instanceof Date
							? ticket.creation_date.toISOString().split('T')[0]
							: ticket.creation_date
					}
				/>
			</div>
		</div>
	);
};

export default TicketForm;
