import React, { ChangeEvent, useState } from 'react';
import '../styling/importclients.css';
import axios from "axios";
import { CLIENTS_URL } from '../util/Constants';

const ImportClients = () => {
  const [csv, setCsv] = useState<File | null>(null);
  const [result, setResult] = useState<string | null>('');

  const handleCsvChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
        setCsv(event.target.files[0]);
      }
  };

  const handleCsvUpload = async () => {
    const formData = new FormData();
    if (csv) {
      formData.append(
          "clients",
          csv,
          csv.name
      );
      const res = await axios.post(`${CLIENTS_URL}import/clients`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (res) {
        setResult(res.data["message"]);
      }
    }
  };

  return (
    <div className="main">
      <h2>Import Client Data</h2> 

      <div>
        <input type="file" accept=".csv" onChange={handleCsvChange} />
        <button className="upload-button" onClick={handleCsvUpload}>Upload Clients</button>
        {result && <p id="result">{result}</p>}
        <div id="csv_details">
          <p>Before you upload please ensure the csv contains the following required columns: </p>
          <ul id="list">
            <li>id: number</li>
            <li>first_name: string</li>
            <li>last_name: string</li>
            <li>name: string</li>
            <li>phones: [string]</li>
            <li>address: string </li>
            <li>street1: string</li>
            <li>city: string</li>
            <li>state: string</li>
            <li>country: string</li>
            <li>zip_code: number</li>
            <li>registration_date: date (Y-m-d)</li>
          </ul>

          <div id="download"> 
            <p>Download here an example CSV with required and optional fields: 
            <a className="download-button" href="https://docs.google.com/spreadsheets/d/1Sz1nhH47sNBQYe6ddjvjU6B1gVzL5mIQ7E-SS0GIalw/edit?usp=sharing">View CSV</a></p>

            <p>Download a XLSX file with more details on the fields: 
            <a className="download-button" href="https://docs.google.com/spreadsheets/d/1IGvDkgwije-Xj6gP_Wx0TSlWmfBB67K9/edit?usp=sharing&ouid=104374524219683226842&rtpof=true&sd=true">View XSLX</a></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImportClients;

// Used as a reference: https://www.geeksforgeeks.org/file-uploading-in-react-js/
