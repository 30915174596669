import React, { useState, useEffect } from 'react';
import '../styling/dashboard.css';
import TodoItem from './TodoItem';
import { Status, Task, Priority } from '../util/Types';
import { CLIENTS_URL } from '../util/Constants';
import axios from 'axios';

const Dashboard = () => {
	const [tasks, setTasks] = useState<Task[]>([]);

	useEffect(() => {
		const promise = axios.get(CLIENTS_URL);
		if (promise === undefined) {
			// this should only happen during testing
			return;
		}
		promise
			.then((response) => {
				const data = response.data;
				if (data.length == 0) {
					setTasks([]);
					return;
				}
				setTasks((tasks) => [
					{
						...tasks,
						id: Date.now(),
						text: data[0].name,
						completed: false,
						last_update: new Date(),
						name: data[0].name,
						address: data[0].address,
						priority: Priority.Medium,
						status: Status.Waiting,
						description: ' ',
					},
				]);
			})
			.catch((err) => console.log(err));
	}, []);

	function deleteTask(id: number) {
		setTasks(tasks.filter((task) => task.id !== id));
	}

	function toggleCompleted(id: number) {
		setTasks(
			tasks.map((task) => {
				if (task.id === id) {
					return { ...task, completed: !task.completed };
				} else {
					return task;
				}
			})
		);
	}

	return (
		<div className="dashboard">
			<div className="header">
				<h1>Dashboard</h1>
			</div>
			<hr />
			<div className="horizontal_wrapper">
				<div className="ticket-list">
					<h1 className="ticketTitle">Ticket Quick View</h1>
					{tasks.map((task) => (
						<TodoItem
							key={task.id}
							task={task}
							deleteTask={deleteTask}
							toggleCompleted={toggleCompleted}
						/>
					))}
				</div>
				<div className="graph">
					<h1 className="graphTitle">Consumer Growth In the Past year</h1>
					<div className="barChart">
						<div className="bar1"></div>
						<div className="bar2"></div>
						<div className="bar3"></div>
					</div>
					<div className="xAxis">
						<span>May</span>
						<span>Jun</span>
						<span>Jul</span>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Dashboard;
