import React, { useMemo, useState, useEffect, memo } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { TextField, Button, Collapse } from '@mui/material';
import { Contact } from '../util/Types';
import '../styling/contacts.css';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { CLIENTS_URL } from '../util/Constants';
import ContactInfo from './ContactInfo';
import axios from 'axios';

const Contacts = memo(function Contacts() {
	const [searchTerm, setSearchTerm] = useState('');
	const [contacts, setContacts] = useState<Contact[]>([]);
	const [expandedContact, setExpandedContact] = useState<Contact | null>(null);
	const [exportMsg, setExportMsg] = useState('');

	useEffect(() => {
		const promise = axios.get(CLIENTS_URL);
		if (promise === undefined) {
			// this should only happen during testing
			return;
		}
		promise
			.then((res) => setContacts(res.data))
			.catch((err) => console.log(err));
	}, []);

	function onClick(id: number) {
		const contact = contacts.find((contact) => contact.id === id);
		if (contact === undefined) {
			return;
		}
		if (expandedContact?.id === id) {
			setExpandedContact(null);
			return;
		}
		setExpandedContact(contact);
	}

	function onChange(contact: Contact) {
		setExpandedContact(contact);
	}

	function getColumns(): GridColDef[] {
		const columns: GridColDef[] = [];
		const fields: string[] = [
			'name',
			'address',
			'zip_code',
			'phones',
			'emails',
			'preferred_contact_method',
			'moreInfo',
		];
		const headerNames: string[] = [
			'CUSTOMER NAME',
			'ADDRESS',
			'ZIP CODE',
			'PHONE',
			'EMAIL',
			'PREFERRED METHOD',
			'MORE INFO',
		];
		const defaultWidth: number = 150;
		const columnWidths: number[] = [];

		for (const field of fields) {
			if (field === 'contactId') {
				columnWidths.push(100);
			} else if (field === 'phoneNumber') {
				columnWidths.push(125);
			} else if (field === 'servicePeriod') {
				columnWidths.push(125);
			} else if (field === 'moreInfo') {
				columnWidths.push(100);
			} else if (field === 'companyName') {
				columnWidths.push(125);
			} else if (field === 'preferred_contact_method') {
				columnWidths.push(175);
			} else if (field === 'zip_code') {
				columnWidths.push(125);
			} else {
				columnWidths.push(defaultWidth);
			}
		}

		for (let i = 0; i < fields.length; i++) {
			columns.push({
				field: fields[i],
				headerName: headerNames[i],
				headerClassName: 'column-header',
				flex: columnWidths[i],
				sortable: false,
				renderCell: (params) => {
					if (fields[i] === 'moreInfo') {
						return (
							<Button
								onClick={() => onClick(params.row.id)}
								size="small"
								startIcon={
									expandedContact?.id === params.row.id ? (
										<KeyboardArrowUp />
									) : (
										<KeyboardArrowDown />
									)
								} // Shows additional info about contacts
							></Button>
						);
					}
					return params.value;
				},
			});
		}
		return columns;
	}
	
	function onSave() {
		if (expandedContact === null) {
			return;
		}
		axios.post(`${CLIENTS_URL}edit-contact/${expandedContact?.id}`, {
			...expandedContact,
		})
		.then(() => {
			// Update the contacts state to reflect changes
			setContacts(prevContacts =>
				prevContacts.map(contact =>
					contact.id === expandedContact.id ? expandedContact : contact
				)
			);
			// Clear the expandedContact state
			setExpandedContact(null);
		})
		.catch(err => console.error('Error saving contact:', err));
		window.location.reload();
	}

	function onReset() {
		const contact = contacts.find((contact) => contact.id === expandedContact?.id);
		if (contact === undefined) {
			return;
		}
		setExpandedContact(contact);
	}

	function onDelete() {
		if (expandedContact === null) {
			return;
		}
		axios.delete(CLIENTS_URL + `${expandedContact.id}`).catch((err) => err);
		window.location.reload();
	}

	const filteredRows = useMemo(() => {
		if (!searchTerm) return contacts;
		return contacts.filter((contact) =>
			Object.values(contact).some(
				(value) =>
					typeof value === 'string' &&
					value.toLowerCase().includes(searchTerm.toLowerCase())
			)
		);
	}, [contacts, searchTerm]);


	const handleCsvExport = async () => {
		const res = await axios.get(`${CLIENTS_URL}export/clients`)
		if (res) {
			setExportMsg(res.data["message"]);
		}
	};

	return (
		<div className="contacts">
			<div
				className="contacts-header"
				style={{ display: 'flex', alignItems: 'center' }}
			>
				<h1 style={{ marginRight: 'auto' }}>Contacts</h1>
				<button
					className="new-client-button"
					onClick={() => (window.location.href = '/contacts/create')}
				>
					New Client +
				</button>
				<button
					onClick={() => (window.location.href = '/contacts/import-clients')}
					className="import-csv-button">
					Import CSV
				</button>
				<button
					onClick={(handleCsvExport)}
					className="export-csv-button"
				>
					Export CSV
				</button>
			</div>
			<hr />
			<div id="export-msg">
				{exportMsg}
			</div>
			<TextField
				label="Search"
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
				style={{ paddingBottom: '5px' }}
			/>
			<div style={{ position: 'relative' }}>
				<div style={{ height: '100%', width: '100%' }}>
					<DataGrid
						rows={filteredRows}
						columns={getColumns()}
						disableRowSelectionOnClick
						initialState={{
							pagination: {
								paginationModel: { page: 0, pageSize: 10 },
							},
						}}
						pageSizeOptions={[10]}
						components={{ NoRowsOverlay: () => <div>No contacts found.</div> }}
					/>
				</div>
				{expandedContact && (
					<Collapse
						in={Boolean(expandedContact)}
						style={{ position: 'absolute', top: '100%', width: '100%' }}
					>
						<ContactInfo 
							contact={expandedContact}
							onChange={onChange}
							onSave={onSave}
							onReset={onReset}
							onDelete={onDelete}
						/>
					</Collapse>
				)}
			</div>
		</div>
	);
});

export default Contacts;
