import React from 'react';
import { Ticket, Status, Priority } from '../util/Types';
import { Button, MenuItem, TextField } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface TicketInfoProps {
	ticket: Ticket;
	onChange: (ticket: Ticket) => void;
	onSave: () => void;
	onReset: () => void;
	onDelete: () => void;
}

const TicketInfo: React.FC<TicketInfoProps> = ({
	ticket,
	onChange,
	onSave,
	onReset,
	onDelete,
}) => {
	const handlePriorityChange = (event: SelectChangeEvent) => {
		onChange({ ...ticket, priority: event.target.value as Priority });
	};

	const handleStatusChange = (event: SelectChangeEvent) => {
		onChange({ ...ticket, status: event.target.value as Status });
	};

	const handleDescriptionChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		onChange({ ...ticket, description: event.target.value });
	};

	return (
		<div
			style={{ background: 'white', padding: '10px', border: '1px solid #ccc' }}
		>
			<TextField label={'ID'} value={ticket.id} disabled />
			<Select
				value={ticket.priority}
				label="PRIORITY"
				onChange={handlePriorityChange}
			>
				{Object.values(Priority).map((priority) => (
					<MenuItem key={priority} value={priority}>
						{priority}
					</MenuItem>
				))}
			</Select>
			<Select
				value={ticket.status}
				label="STATUS"
				onChange={handleStatusChange}
			>
				{Object.values(Status).map((status) => (
					<MenuItem key={status} value={status}>
						{status}
					</MenuItem>
				))}
			</Select>

			<TextField
				label={'CONTACT NAME'}
				value={ticket.contact_name || 'No contact found'}
				disabled
			/>
			<TextField label={'AUTHOR'} value={ticket.author} disabled />
			<TextField
				label={'CREATION DATE'}
				value={ticket.creation_date}
				disabled
			/>
			<div>
				<TextField
					style={{ marginTop: '1rem' }}
					label={'DESCRIPTION'}
					value={ticket.description}
					multiline
					onChange={handleDescriptionChange}
				/>
			</div>
			<div style={{ marginTop: '1rem' }}>
				<Button variant="contained" color="primary" onClick={onSave}>
					Save
				</Button>
				<Button variant="contained" color="info" onClick={onReset}>
					Reset
				</Button>
				<Button variant="contained" color="error" onClick={onDelete}>
					Delete
				</Button>
			</div>
		</div>
	);
};

export default TicketInfo;
