import React, { useState, useEffect } from 'react';
import TicketForm from './newTicketForm/TicketForm';
import '../styling/createclient.css';
import { Ticket, Priority, Status } from '../util/Types';
import { TICKETS_URL } from '../util/Constants';
import axios from 'axios';
import { CLIENTS_URL } from '../util/Constants';
import { Contact } from '../util/Types';

const CreateTicket = () => {
	const [contacts, setContacts] = useState<Contact[]>([]);
	const [ticket, setTicket] = useState<Ticket>({
		priority: Priority.Low,
		status: Status.New,
		description: '',
		contact_name: '',
		contact_id: 0,
		author: '',
		creation_date: new Date(),
	});

	useEffect(() => {
		const promise = axios.get(CLIENTS_URL);
		if (promise === undefined) {
			return;
		}
		promise
			.then((res) => {
				setContacts(res.data);
				if (res.data.length !== 0) {
					setTicket((prev) => ({ ...prev, contact_id: res.data[0].id }));
				}
			})
			.catch((err) => console.log(err));
	}, []);

	function onChange(newTicket: Ticket) {
		setTicket(newTicket);
	}

	function onSubmit() {
		const associatedContact = contacts.find(
			(contact) => contact.id === ticket.contact_id
		);
		if (associatedContact !== undefined) {
			ticket.contact_name = associatedContact.name;
		}
		const data = ticket;
		axios
			.post(TICKETS_URL, { data })
			.then((res) => console.log(res))
			.catch((err) => console.log(err));
		window.location.href = '/tickets';
	}

	return (
		<div className="create-client">
			<div className="create-client-header">
				<h1>Create New Ticket</h1>
			</div>
			<hr />
			<form
				onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
					event.preventDefault();
					onSubmit();
				}}
			>
				<TicketForm ticket={ticket} contacts={contacts} onChange={onChange} />
				<button type="submit">Submit</button>
			</form>
		</div>
	);
};

export default CreateTicket;
