import React from 'react';
import cisIcon from '../img/cis_icon.png';
import dashboardIcon from '../img/dashboard_icon.svg';
import contactsIcon from '../img/contacts_icon.svg';
import ticketsIcon from '../img/tickets_icon.svg';
import { Page } from '../util/Types';
import '../styling/sidebar.css';

const Sidebar = () => {
	let currentPage: Page;

	if (window.location.href.includes('/dashboard')) {
		currentPage = Page.Dashboard;
	} else if (window.location.href.includes('/tickets')) {
		currentPage = Page.Tickets;
	} else if (window.location.href.includes('/contacts')) {
		currentPage = Page.Contacts;
	} else {
		currentPage = Page.NotFound;
	}

	return (
		<div className="sidenav">
			<img
				className="cis-icon"
				alt="Community Internet Services Icon"
				src={cisIcon}
			/>
			<div
				className={currentPage === Page.Dashboard ? 'selected-div' : ''}
				onClick={() => (window.location.href = '/dashboard')}
				onKeyDown={() => (window.location.href = '/dashboard')}
			>
				<img className="small-icon" alt="Dashboard Icon" src={dashboardIcon} />{' '}
				Dashboard
			</div>
			<div
				className={currentPage === Page.Tickets ? 'selected-div' : ''}
				onClick={() => (window.location.href = '/tickets')}
				onKeyDown={() => (window.location.href = '/tickets')}
			>
				<img className="small-icon" alt="Tickets Icon" src={ticketsIcon} />{' '}
				Tickets
			</div>
			<div
				className={currentPage === Page.Contacts ? 'selected-div' : ''}
				data-testid="testing-tickets-sidenav-button"
				onClick={() => (window.location.href = '/contacts')}
				onKeyDown={() => (window.location.href = '/contacts')}
			>
				<img className="small-icon" alt="Contacts Icon" src={contactsIcon} />{' '}
				Contacts
			</div>
		</div>
	);
};

export default Sidebar;
