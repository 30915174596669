import React, { useState } from 'react';
import ClientForm from './newContactForm/ContactForm';
import AdvancedClientForm from './newContactForm/AdvancedContactForm';
import { CLIENTS_URL } from '../util/Constants';
import '../styling/createclient.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import axios from 'axios';

import { Contact, getDefaultContact } from '../util/Types';

const CreateClient = () => {
	const [contact, setContact] = useState<Contact>(getDefaultContact());

	function onChange(newClient: Contact) {
		setContact(newClient);
	}

	function onSubmit() {
		contact.name = contact.first_name + ' ' + contact.last_name;
		contact.address =
			contact.street1 +
			' ' +
			(contact.street2 ? contact.street2 : '')
			', ' +
			contact.country +
			' ' +
			contact.zip_code;
		contact.registration_date = new Date();
		axios
			.post(CLIENTS_URL, { contact })
			.then((res) => console.log(res))
			.catch((err) => console.log(err));
		window.location.href = '/contacts';
	}

	return (
		<div className="create-client">
			<div className="create-client-header">
				<h1>Add New Contact</h1>
			</div>
			<hr />
			<form
				onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
					event.preventDefault();
					onSubmit();
				}}
			>
				<ClientForm contact={contact} onChange={onChange} />
				<Accordion>
					<AccordionSummary>
						<b>Advanced Options +</b>
					</AccordionSummary>
					<AccordionDetails>
						<AdvancedClientForm contact={contact} onChange={onChange} />
					</AccordionDetails>
				</Accordion>
				<button type="submit">Submit</button>
			</form>
		</div>
	);
};

export default CreateClient;
