import React from 'react';
import { Contact } from '../../util/Types';

type ContactFormProps = {
	contact: Contact;
	onChange: (contact: Contact) => void;
};

const ContactForm = ({ contact, onChange }: ContactFormProps) => {
	return (
		<div>
			<div>
				<label>
					First Name<span style={{ color: 'red' }}> *</span>
				</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({ ...contact, first_name: event.target.value });
					}}
					required
				/>
				<label>
					Last Name<span style={{ color: 'red' }}> *</span>
				</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({ ...contact, last_name: event.target.value });
					}}
					required
				/>
			</div>
			<label>
				Phone Number<span style={{ color: 'red' }}> *</span>
			</label>
			<input
				className="input-field"
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
					onChange({ ...contact, phones: [event.target.value] });
				}}
				pattern="^\d{10}$|^\d{3}-\d{3}-\d{4}$"
				required
			/>
			<div>
				<div>
					<label>
						Address<span style={{ color: 'red' }}> *</span>
					</label>
					<input
						className="input-field"
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							onChange({ ...contact, street1: event.target.value });
						}}
						required
					/>
					<label>Address 2</label>
					<input
						className="input-field"
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							onChange({ ...contact, street2: event.target.value });
						}}
					/>
				</div>
				<label>
					City<span style={{ color: 'red' }}> *</span>
				</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({ ...contact, city: event.target.value });
					}}
					required
				/>
				<label htmlFor="state">
					State<span style={{ color: 'red' }}> *</span>
				</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({ ...contact, state: event.target.value });
					}}
					required
				/>
				<label htmlFor="zipCode">
					Zip Code<span style={{ color: 'red' }}> *</span>
				</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({ ...contact, zip_code: parseInt(event.target.value) });
					}}
					pattern="^\d{5}$"
					required
				/>
			</div>
		</div>
	);
};

export default ContactForm;
