import React from 'react';
import { Link } from 'react-router-dom';
import { AUTH_LOGIN } from '../util/Constants';

const Unauthorized: React.FC = () => {
	return (
		<div className="h-screen flex justify-center items-center bg-gradient-to-b from-blue-800 to-white-800">
			<div className="text-center">
				<h1 className="text-6xl font-bold text-white mb-4">401 Unauthorized</h1>
				<p className="text-xl text-gray-100 mb-4">
					Sorry, you do not have access to this page.
				</p>
				<Link
					to={AUTH_LOGIN}
					className="text-xl font-bold text-gray-100 hover:underline"
				>
					Please login with a verified account.
				</Link>
			</div>
		</div>
	);
};

export default Unauthorized;
