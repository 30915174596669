import { Contact } from '../util/Types';
import React, { useEffect, useState } from 'react';
import {
	Button,
	TextField,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ContactInfoProps {
	contact: Contact;
	onChange: (contact: Contact) => void;
	onSave: () => void;
	onReset: () => void;
	onDelete: () => void;
}

interface FieldCategory {
	name: string;
	fields: (keyof Contact | number | Date | string)[];
	types: string[];
}

const ContactInfo: React.FC<ContactInfoProps> = ({
	contact,
	onChange,
	onSave,
	onReset,
	onDelete,
}) => {
	const [editedContact, setEditedContact] = useState<Contact>(contact);
	const [categoryEdits, setCategoryEdits] = useState<{
		[key: string]: Contact;
	}>({});

	useEffect(() => {
		// Update the editedContact state whenever the contact prop changes
		setEditedContact(contact);
		setCategoryEdits({});
	}, [contact]);

	const handleChange = (
		category: string,
		key: string,
		value: string | number | boolean | Array<string>
	) => {
		setEditedContact({ ...editedContact, [key]: value });
		const updatedCategoryEdits = { ...categoryEdits };
		updatedCategoryEdits[category] = {
			...updatedCategoryEdits[category],
			[key]: value,
		};
		setCategoryEdits(updatedCategoryEdits);
		onChange({ ...editedContact, ...updatedCategoryEdits[category] });
	};

	const handleReset = () => {
		setEditedContact(contact);
		setCategoryEdits({});
		onReset();
	};

	const handleDelete = () => {
		onDelete();
	};

	const disabledFields = ['id', 'name', 'address'];

	const fieldCategories: FieldCategory[] = [
		{
			name: 'Personal Information',
			fields: [
				'id',
				'custom_id',
				'username',
				'name',
				'first_name',
				'last_name',
				'registration_date',
				'balance',
			],
			types: [
				'number',
				'number',
				'string',
				'string',
				'string',
				'string',
				'Date',
				'number',
			],
		},
		{
			name: 'Contact Information',
			fields: [
				'emails',
				'phones',
				'address',
				'street1',
				'street2',
				'city',
				'state',
				'zip_code',
				'country',
				'preferred_contact_method',
			],
			types: [
				'string[], comma separated',
				'string[], comma separated',
				'string',
				'string',
				'string',
				'string',
				'string',
				'number',
				'string',
				'string',
			],
		},
		{
			name: 'Company Information',
			fields: [
				'company_name',
				'is_lead',
				'company_registration_number',
				'company_tax_id',
				'company_website',
			],
			types: ['string', 'boolean (true/false)', 'number', 'number', 'string'],
		},
		{
			name: 'Invoice Information',
			fields: [
				'invoice_address',
				'invoice_street1',
				'invoice_street2',
				'invoice_city',
				'invoice_country',
				'invoice_state',
				'invoice_zip_code',
			],
			types: [
				'string',
				'string',
				'string',
				'string',
				'string',
				'string',
				'number',
			],
		},
		{
			name: 'Service Information',
			fields: [
				'service',
				'service_period',
				'service_individual_price',
				'service_invoice_label',
				'service_latitude',
				'service_longitude',
				'service_note',
				'service_active_from',
				'service_active_to',
				'service_invoicing_from',
				'service_invoicing_type',
				'service_contract_type',
				'service_contract_end_date',
				'service_invoicing_period_start_day',
				'service_create_invoice_days_advance',
				'service_invoice_separately',
				'service_invoice_use_credit_auto',
				'service_min_contract_length',
				'service_setup_fee',
				'service_early_termination_fee',
				'service_invoice_approve_send_auto',
				'location_id',
				'service_tax_name_1',
				'service_tax_name_2',
				'service_tax_name_3',
				'service_tax_rate_1',
				'service_tax_rate_2',
				'service_tax_rate_3',
				'service_contact_id',
				'service_census_block_geoid',
				'service_prepaid',
			],
			types: [
				'string',
				'number',
				'number',
				'string',
				'string',
				'string',
				'string',
				'Date',
				'Date',
				'Date',
				'string',
				'string',
				'Date',
				'number',
				'number',
				'number',
				'number',
				'number',
				'number',
				'number',
				'number',
				'number',
				'string',
				'string',
				'string',
				'number',
				'number',
				'number',
				'number',
				'number',
				'number',
			],
		},
		{
			name: 'Miscellaneous',
			fields: [
				'note',
				'archived',
				'school_age_children',
				'elderly',
				'currently_have_internet',
				'current_isp',
				'createdAt',
				'updatedAt',
				'misc',
			],
			types: [
				'string[], comma separated',
				'boolean (true/false)',
				'boolean (true/false)',
				'boolean (true/false)',
				'boolean (true/false)',
				'string',
				'Date',
				'Date',
				'string[], comma separated',
			],
		},
	];

	const parseFieldValue = (
		value: string,
		type: string
	): string | number | boolean | string[] => {
		if (type === 'number') {
			return parseInt(value);
		}
		if (type === 'boolean') {
			return Boolean(value);
		}
		if (type.includes('string[]')) {
			return value.split(',');
		}
		return value; // if Date, it should be fine to leave it as a string
	};

	const getValue = (field: string | number | Date) => {
		return field === 'updatedAt' || field === 'createdAt'
			? (editedContact[field as keyof Contact] as Date)?.toString() || ''
			: editedContact[field as keyof Contact] || '';
	};

	const getInput = (
		field: string | number | Date,
		index: number,
		category: FieldCategory
	) => {
		const type = category.types[index];
		const onChange = (
			e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
		) => {
			handleChange(
				category.name,
				field as string,
				parseFieldValue(e.target.value, category.types[index])
			);
		};

		if (type !== 'Date') {
			return (
				<TextField
					key={String(field)}
					label={`${String(field).toUpperCase()}: ${category.types[
						index
					].toUpperCase()}`}
					value={getValue(field)}
					onChange={onChange}
					fullWidth
					style={{ marginBottom: '10px' }}
					disabled={disabledFields.includes(field as keyof Contact)}
				/>
			);
		} else {
			const rawDate = String(getValue(field));
			const value =
				rawDate === '' ? '' : new Date(rawDate).toISOString().slice(0, 10);
			return (
				<div key={String(field)}>
					<label>{String(field).toUpperCase()}</label>
					<input
						type="date"
						id="creationDateInput"
						name="creationDate"
						className="input-field"
						onChange={onChange}
						value={value}
					/>
				</div>
			);
		}
	};

	return (
		<div
			className="additional-info"
			style={{ background: 'white', padding: '10px', border: '1px solid #ccc' }}
		>
			<div>
				<Typography
					variant="h6"
					style={{ marginBottom: '10px' }}
				>{`${contact.name}`}</Typography>
				{fieldCategories.map((category) => (
					<Accordion key={category.name}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>{category.name}</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: '10px',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										gap: '10px',
									}}
								>
									{category.fields.map((field, index) =>
										getInput(field, index, category)
									)}
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
				))}
				<div style={{ marginTop: '1rem' }}>
					<Button variant="contained" color="primary" onClick={onSave}>
						Save
					</Button>
					<Button variant="contained" color="info" onClick={handleReset}>
						Reset
					</Button>
					<Button variant="contained" color="error" onClick={handleDelete}>
						Delete
					</Button>
				</div>
			</div>
		</div>
	);
};

export default ContactInfo;
