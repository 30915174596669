import React from 'react';
import { Contact } from '../../util/Types';
import ServiceOptionsFrom from './ServiceOptionsForm';

type AdvancedContactFormProps = {
	contact: Contact;
	onChange: (contact: Contact) => void;
};

const AdvancedContactForm = ({
	contact,
	onChange,
}: AdvancedContactFormProps) => {
	return (
		<>
			<div>
				<label>Custom ID</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({ ...contact, custom_id: parseInt(event.target.value) });
					}}
				/>
			</div>
			<div>
				<label>Username</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({ ...contact, username: event.target.value });
					}}
				/>
			</div>
			<div>
				<label>Company Name</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({ ...contact, company_name: event.target.value });
					}}
				/>
			</div>
			<div>
				<label>Is Lead?</label>
				<label>
					<input
						className="input-checkbox-v"
						type="checkbox"
						value="yes"
						checked={contact.is_lead}
						onChange={() => {
							onChange({ ...contact, is_lead: !contact.is_lead });
						}}
					/>
				</label>
			</div>
			<div>
				<label>Company Registration Number</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							company_registration_number: parseInt(event.target.value),
						});
					}}
				/>
			</div>
			<div>
				<label>Company Website</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({ ...contact, company_website: event.target.value });
					}}
				/>
			</div>
			<div>
				<label>Email</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({ ...contact, emails: [event.target.value] });
					}}
				/>
			</div>
			<h3
				style={{ textAlign: 'left', fontSize: '1em', fontWeight: 'bold' }}
				className="pb-5 pt-5"
			>
				Invoice Address
			</h3>
			<div>
				<label>Address Line 1</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({ ...contact, invoice_street1: event.target.value });
					}}
				/>
			</div>
			<div>
				<label>Address Line 2</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({ ...contact, invoice_street2: event.target.value });
					}}
				/>
			</div>
			<div>
				<label>City</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({ ...contact, invoice_city: event.target.value });
					}}
				/>
			</div>
			<div>
				<label>State</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({ ...contact, invoice_state: event.target.value });
					}}
				/>
			</div>
			<div>
				<label>Zip Code</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							invoice_zip_code: parseInt(event.target.value),
						});
					}}
					pattern="^\d{5}$"
				/>
			</div>
			<h3
				style={{ textAlign: 'left', fontSize: '1em', fontWeight: 'bold' }}
				className="pb-5 pt-5"
			>
				More Options
			</h3>
			<div>
				<label>Note</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({ ...contact, note: [event.target.value] });
					}}
				/>
			</div>
			<div>
				<label>Archived?</label>
				<label>
					<input
						className="input-checkbox-v"
						type="checkbox"
						value="yes"
						checked={contact.archived}
						onChange={() => {
							onChange({ ...contact, archived: !contact.archived });
						}}
					/>
				</label>
			</div>
			<div>
				<label>School Aged Children?</label>
				<label>
					<input
						className="input-checkbox-v"
						type="checkbox"
						value="yes"
						checked={contact.school_age_children}
						onChange={() => {
							onChange({
								...contact,
								school_age_children: !contact.school_age_children,
							});
						}}
					/>
				</label>
			</div>
			<div>
				<label>Someone over 65 years old at the home?</label>
				<label>
					<input
						className="input-checkbox-v"
						type="checkbox"
						value="yes"
						checked={contact.elderly}
						onChange={() => {
							onChange({
								...contact,
								elderly: !contact.elderly,
							});
						}}
					/>
				</label>
			</div>
			<div>
				<label>Current Has Internet?</label>
				<label>
					<input
						className="input-checkbox-v"
						type="checkbox"
						value="yes"
						checked={contact.currently_have_internet}
						onChange={() => {
							onChange({
								...contact,
								currently_have_internet: !contact.currently_have_internet,
							});
						}}
					/>
				</label>
			</div>
			<div>
				<label>Current ISP</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({ ...contact, current_isp: event.target.value });
					}}
				/>
			</div>
			<div>
				<label>Preferred Contact Method (phone, email, mail)</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							preferred_contact_method: event.target.value,
						});
					}}
				/>
			</div>
			<div>
				<label>Location ID</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							location_id: parseInt(event.target.value),
						});
					}}
					pattern="^[0-9]*$"
				/>
			</div>
			<ServiceOptionsFrom contact={contact} onChange={onChange} />
		</>
	);
};

export default AdvancedContactForm;
