import React from 'react';
import { Contact } from '../../util/Types';

type ServiceOptionsFormProps = {
	contact: Contact;
	onChange: (contact: Contact) => void;
};

const ServiceOptionsForm = ({ contact, onChange }: ServiceOptionsFormProps) => {
	return (
		<>
			<div>
				<label>Service</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({ ...contact, service: event.target.value });
					}}
				/>
			</div>
			<div>
				<label>Service Period</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							service_period: parseInt(event.target.value),
						});
					}}
					pattern="^[0-9]$"
				/>
			</div>
			<div>
				<label>Service Individual Price</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							service_individual_price: parseInt(event.target.value),
						});
					}}
					pattern="^[0-9]$"
				/>
			</div>
			<div>
				<label>Service Invoice Label</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({ ...contact, service_invoice_label: event.target.value });
					}}
				/>
			</div>
			<div>
				<label>Service Latitude</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({ ...contact, service_latitude: event.target.value });
					}}
				/>
			</div>
			<div>
				<label>Service Longitude</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({ ...contact, service_longitude: event.target.value });
					}}
				/>
			</div>
			<div>
				<label>Service Note</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({ ...contact, service_note: event.target.value });
					}}
				/>
			</div>
			<div>
				<label>Service Active From</label>
				<input
					type="date"
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							service_active_from: new Date(event.target.value),
						});
					}}
				/>
			</div>
			<div>
				<label>Service Active To</label>
				<input
					type="date"
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							service_active_to: new Date(event.target.value),
						});
					}}
				/>
			</div>
			<div>
				<label>Service Invoicing From</label>
				<input
					type="date"
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							service_invoicing_from: new Date(event.target.value),
						});
					}}
				/>
			</div>
			<div>
				<label>Service Contract End Date</label>
				<input
					type="date"
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							service_contract_end_date: new Date(event.target.value),
						});
					}}
				/>
			</div>
			<div>
				<label>Service Invoicing Type</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							service_invoicing_type: event.target.value,
						});
					}}
				/>
			</div>
			<div>
				<label>Service Contact Type</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({ ...contact, service_contract_type: event.target.value });
					}}
				/>
			</div>
			<div>
				<label>Service Invoicing Period Start Day</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							service_invoicing_period_start_day: parseInt(event.target.value),
						});
					}}
					pattern="^[0-9]*$"
				/>
			</div>
			<div>
				<label>Service Create Invoice Days Advance</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							service_create_invoice_days_advance: parseInt(event.target.value),
						});
					}}
					pattern="^[0-9]*$"
				/>
			</div>
			<div>
				<label>Service Invoice Separately</label>
				<label>
					<input
						className="input-field"
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							onChange({
								...contact,
								service_invoice_separately: parseInt(event.target.value),
							});
						}}
					/>
				</label>
			</div>
			<div>
				<label>Service Invoice Use Credit Automatically</label>
				<label>
					<input
						className="input-field"
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							onChange({
								...contact,
								service_invoice_use_credit_auto: parseInt(event.target.value),
							});
						}}
					/>
				</label>
			</div>
			<div>
				<label>Service Minimum Contact Length</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							service_min_contract_length: parseInt(event.target.value),
						});
					}}
					pattern="^[0-9]*$"
				/>
			</div>
			<div>
				<label>Service Statup Fee</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							service_setup_fee: parseInt(event.target.value),
						});
					}}
					pattern="^[0-9]*$"
				/>
			</div>
			<div>
				<label>Service Early Termination Fee</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							service_early_termination_fee: parseInt(event.target.value),
						});
					}}
					pattern="^[0-9]*$"
				/>
			</div>
			<div>
				<label>Service Invoice Approval Sent Automatically</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							service_invoice_approve_send_auto: parseInt(event.target.value),
						});
					}}
					pattern="^[0-9]*$"
				/>
			</div>
			<div>
				<label>Service Tax Name 1</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							service_tax_name_1: event.target.value,
						});
					}}
					pattern="^[0-9]*$"
				/>
			</div>

			<div>
				<label>Service Tax Name 2</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							service_tax_name_2: event.target.value,
						});
					}}
					pattern="^[0-9]*$"
				/>
			</div>

			<div>
				<label>Service Tax Name 3</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							service_tax_name_3: event.target.value,
						});
					}}
					pattern="^[0-9]*$"
				/>
			</div>

			<div>
				<label>Service Tax Rate 1</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							service_tax_rate_1: parseInt(event.target.value),
						});
					}}
					pattern="^[0-9]*$"
				/>
			</div>

			<div>
				<label>Service Tax Rate 2</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							service_tax_rate_2: parseInt(event.target.value),
						});
					}}
					pattern="^[0-9]*$"
				/>
			</div>

			<div>
				<label>Service Tax Rate 3</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							service_tax_rate_3: parseInt(event.target.value),
						});
					}}
					pattern="^[0-9]*$"
				/>
			</div>

			<div>
				<label>Service Contact ID</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							service_contact_id: parseInt(event.target.value),
						});
					}}
					pattern="^[0-9]*$"
				/>
			</div>

			<div>
				<label>Service Census Block Geo ID</label>
				<input
					className="input-field"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onChange({
							...contact,
							service_census_block_geoid: parseInt(event.target.value),
						});
					}}
					pattern="^[0-9]*$"
				/>
			</div>
		</>
	);
};

export default ServiceOptionsForm;
