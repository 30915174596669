import React from 'react';
import { useCollapse } from 'react-collapsed';
import { CollapsibleProps, TodoItemProps} from '../util/Types';
import Select from 'react-select'

  import '../styling/todoitem.css';

  function convertTo12HourFormatWithDate(date: Date) {
    const year = date.getFullYear();
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const meridiem = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12 || 12;

    const paddedMinutes = String(minutes).padStart(2, '0');
    const paddedSeconds = String(seconds).padStart(2, '0');

    const formattedDate = `${month} ${day} ${year}`;
    const formattedTime = `${hours}:${paddedMinutes}:${paddedSeconds} ${meridiem}`;

    return `${formattedDate} ${formattedTime}`;
}
  function Collapsible({ task, children }: CollapsibleProps) {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
    return (
      <div className="collapsible">
        <div className="item-header" {...getToggleProps()}>
          {isExpanded ?  task.text : task.text}
        </div>
        <div {...getCollapseProps()}>
          <div className="content">{children}</div>
        </div>
      </div>
    );
}

  const TodoItem: React.FC<TodoItemProps> = ({
    task,
    deleteTask,
    toggleCompleted,
  }) => {
    const handleChange = () => {
      toggleCompleted(task.id);
    };
  
    
  const statusOptions = [
    { value: 'Lost', label: 'tried, but not ACP approved - lost interest' },
    { value: 'Blocked', label: 'Blocked LOS' },
    { value: 'Landlord', label: 'Blocked by landlord' },
    { value: 'Calling', label: 'Calling' },
    { value: 'New', label: 'Brand new - untouched' },
    { value: 'Out', label: 'Out of Service Area' },
    { value: 'Waiting', label: 'Waiting on Survey' },
    { value: 'Scheduled', label: 'Install Scheduled' },
    { value: 'Installed', label: 'Installed' },
    { value: 'Schedule_installed', label: 'Good to schedule install' },
    { value: 'No_interest', label: 'No longer interested' },
    { value: 'Rejected', label: 'Rejected' }
];

const priorityOptions = [
  { value: 'Low', label: 'Low' },
  { value: 'Medium', label: 'Medium' },
  { value: 'High', label: 'High' }
];


function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
      // Prevent the browser from reloading the page
      e.preventDefault();
  
      // Read the form data
      const form = e.target as HTMLFormElement;
    const formData = new FormData(form);


    const formJson = Object.fromEntries(formData.entries());
    const description = formJson.myInput;
    console.log(description);
}

    return (
      <div className="todo-item">
        <input
          type="checkbox"
          checked={task.completed}
          onChange={handleChange}/>
          
        <Collapsible task={task}> 
        <div className ="ticket_attribute">
          <div className="attribute_text"> Last update:</div>
          <div className="attribute_data">{convertTo12HourFormatWithDate(task.last_update)}</div>
        </div>
        <div className ="ticket_attribute">
          <div className="attribute_text"> Address:</div>
          <div className="attribute_data">{task.address}</div>
        </div>
        <div className ="ticket_attribute">
          <div className="attribute_text"> Name:</div>
          <div className="attribute_data">{task.name}</div>
        </div>        
        <div className ="ticket_attribute">
          <div className="attribute_text"> Status:</div>
          <div className="attribute_data">
          <Select options={statusOptions} />
          </div>
        </div>
        <div className ="ticket_attribute">
          <div className="attribute_text"> Priority:</div>
          <div className="attribute_data">
          <Select options={priorityOptions} />
          </div>
        </div>

        <div className ="ticket_attribute">
          <div className="attribute_text"> Description/notes:</div>
   
          <div className = 'attribute_data' >
            <form method="post" onSubmit={handleSubmit}>
              <label>
                <textarea name="myInput" defaultValue="Some initial value" />
              </label>
              <div className = 'submit'> 
                  <button type="submit">Save description</button>
              </div>

            </form>
        </div>


        </div>
        </Collapsible>
        
        <button onClick={() => deleteTask(task.id)}>X</button>
      </div>
    );
  };

  export default TodoItem;
