import React, { useState, useEffect, memo } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Collapse, Button } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Ticket } from '../util/Types';
import { TICKETS_URL } from '../util/Constants';
import TicketInfo from './TicketInfo';
import '../styling/tickets.css';
import axios from 'axios';

const Tickets = memo(function Tickets() {
	const [tickets, setTickets] = useState<Ticket[]>([]);
	const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);

	useEffect(() => {
		const promise = axios.get(TICKETS_URL);
		if (promise === undefined) {
			// this should only happen during testing
			return;
		}
		promise
			.then((res) => setTickets(res.data))
			.catch((err) => console.log(err));
	}, []);

	function onClick(id: number) {
		const ticket = tickets.find((ticket) => ticket.id === id);
		if (ticket === undefined) {
			return;
		}
		if (selectedTicket?.id === id) {
			setSelectedTicket(null);
			return;
		}
		setSelectedTicket(ticket);
	}

	function onChange(ticket: Ticket) {
		setSelectedTicket(ticket);
	}

	function getColumns(): GridColDef[] {
		const columns: GridColDef[] = [];
		const fields: string[] = [
			'id',
			'priority',
			'status',
			'description',
			'contact_name',
			'author',
			'creation_date',
			'more_info',
		];
		const headerNames: string[] = [
			'TICKET ID',
			'PROIRITY',
			'STATUS',
			'DESCRIPTION',
			'CONTACT NAME',
			'AUTHOR',
			'CREATION DATE',
			'MORE_INFO',
		];
		const defaultWidth: number = 170;
		const columnWidths: number[] = [];
		for (const field of fields) {
			if (
				field === 'id' ||
				field === 'contactId' ||
				field === 'priority' ||
				field === 'status'
			) {
				columnWidths.push(90);
			} else if (field === 'creationDate') {
				columnWidths.push(200);
			} else {
				columnWidths.push(defaultWidth);
			}
		}
		for (let i = 0; i < fields.length; i++) {
			columns.push({
				field: fields[i],
				headerName: headerNames[i],
				headerClassName: 'column-header',
				flex: columnWidths[i],
				sortable: true,
				renderCell: (params) => {
					if (fields[i] === 'more_info') {
						return (
							<Button
								onClick={() => onClick(params.row.id)}
								size="small"
								startIcon={
									selectedTicket?.id === params.row.id ? (
										<KeyboardArrowUp />
									) : (
										<KeyboardArrowDown />
									)
								} // Shows additional info about contacts
							></Button>
						);
					}
					return params.value;
				},
			});
		}
		return columns;
	}

	function onSave() {
		if (selectedTicket === null) {
			return;
		}
		const data = selectedTicket;
		axios.patch(TICKETS_URL + `${data.id}`, { data }).catch((err) => err);
		window.location.reload();
	}

	function onReset() {
		const ticket = tickets.find((ticket) => ticket.id === selectedTicket?.id);
		if (ticket === undefined) {
			return;
		}
		setSelectedTicket(ticket);
	}

	function onDelete() {
		if (selectedTicket === null) {
			return;
		}
		axios.delete(TICKETS_URL + `${selectedTicket.id}`).catch((err) => err);
		window.location.reload();
	}

	return (
		<div className="tickets">
			<div className="tickets-header">
				<h1 data-testid="testing-tickets-title">Tickets</h1>
				<button
					className="create-ticket-button"
					data-testid="testing-tickets-create-button"
					onClick={() => (window.location.href = '/tickets/create')}
				>
					Create Ticket +
				</button>
			</div>
			<hr />
			<div style={{ position: 'relative' }}>
				<DataGrid
					data-testid="testing-tickets-datagrid"
					rows={tickets}
					columns={getColumns()}
					disableRowSelectionOnClick
					initialState={{
						pagination: {
							paginationModel: { page: 0, pageSize: 10 },
						},
					}}
					pageSizeOptions={[10]}
				/>
				{selectedTicket && (
					<Collapse
						in={Boolean(selectedTicket)}
						style={{ position: 'absolute', top: '100%', width: '100%' }}
					>
						<TicketInfo
							ticket={selectedTicket}
							onChange={onChange}
							onSave={onSave}
							onReset={onReset}
							onDelete={onDelete}
						/>
					</Collapse>
				)}
			</div>
		</div>
	);
});

export default Tickets;
