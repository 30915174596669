export enum Page {
	Dashboard,
	Tickets,
	Contacts,
	NotFound,
}

export interface TodoItemProps {
	task: Task;
	deleteTask: (id: number) => void;
	toggleCompleted: (id: number) => void;
}

export interface CollapsibleProps {
	task: Task;
	children: React.ReactNode;
}

export interface Task {
	id: number;
	text: string;
	last_update: Date;
	address: string;
	name: string;
	priority: Priority;
	status: Status;
	completed: boolean;
	description: string;
}

export enum Priority {
	Low = 'LOW',
	Medium = 'MEDIUM',
	High = 'LARGE',
}

export enum Status {
	Lost = 'tried, but not ACP approved - lost interest',
	Blocked = 'Blocked LOS',
	Landlord = 'Blocked by landlord',
	Calling = 'Calling',
	New = 'Brand new - untouched',
	Out = 'Out of Service Area',
	Waiting = 'Waiting on Survey',
	Scheduled = 'Install Scheduled',
	Installed = 'Installed',
	Schedule_installed = 'Good to schedule install',
	No_interest = 'No longer interested',
	Rejected = 'Rejected',
}

export type Ticket = {
	id?: number;
	priority: Priority;
	status: Status;
	description: string;
	contact_name: string;
	contact_id: number;
	author: string;
	creation_date: Date;
};

export function getDefaultContact(): Contact {
	return {
		first_name: '',
		last_name: '',
		name: '',
		phones: [],
		address: '',
		street1: '',
		street2: '',
		city: '',
		state: '',
		country: 'USA',
		zip_code: 0,
		registration_date: new Date(),
	};
}

export type Contact = {
	id?: number;
	custom_id?: number;
	first_name: string;
	last_name: string;
	name: string;
	username?: string;
	company_name?: string;
	is_lead?: boolean;
	company_registration_number?: number;
	company_website?: string;
	emails?: string[];
	phones: string[];
	address: string;
	balance?: number;
	street1: string;
	street2?: string;
	city: string;
	country: string;
	state: string;
	zip_code: number;
	invoice_street1?: string;
	invoice_street2?: string;
	invoice_city?: string;
	invoice_country?: string;
	invoice_state?: string;
	invoice_zip_code?: number;
	invoice_address?: string;
	registration_date: Date;
	note?: string[];
	archived?: boolean;
	school_age_children?: boolean;
	elderly?: boolean;
	currently_have_internet?: boolean;
	current_isp?: string;
	preferred_contact_method?: string;
	service?: string;
	service_period?: number;
	service_individual_price?: number;
	service_invoice_label?: string;
	service_latitude?: string;
	service_longitude?: string;
	service_note?: string;
	service_active_from?: Date;
	service_active_to?: Date;
	service_invoicing_from?: Date;
	service_invoicing_type?: string;
	service_contract_type?: string;
	service_contract_end_date?: Date;
	service_invoicing_period_start_day?: number;
	service_create_invoice_days_advance?: number;
	service_invoice_separately?: number;
	service_invoice_use_credit_auto?: number;
	service_min_contract_length?: number;
	service_setup_fee?: number;
	service_early_termination_fee?: number;
	service_invoice_approve_send_auto?: number;
	location_id?: number;
	service_tax_name_1?: string;
	service_tax_name_2?: string;
	service_tax_name_3?: string;
	service_tax_rate_1?: number;
	service_tax_rate_2?: number;
	service_tax_rate_3?: number;
	service_contact_id?: number;
	service_census_block_geoid?: number;
	service_prepaid?: number;
	misc?: string[];
};
